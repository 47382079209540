// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import * as bootstrap from 'bootstrap'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import CurrencyInput from '@/components/CurrencyInput.vue'
import Datepicker from 'vue3-datepicker'
import Swal from 'sweetalert2'

import Header from '@/components/Header.vue'
import { journalFactory } from '@/http/journal'
import { accountFactory } from '@/http/account'
import { staticFactory } from '@/http/static'
import Utils from '@/utils/utils'
@Options({
  components: {
    Header,
    Datepicker,
    CurrencyInput,
    VPagination
  },
  data() {
    return {
      journals: [],
      accounts: [],
      alljournals: [],
      journalMaps2: [],
      journalMap2Types: [],
      selectedType: null,
      selectedMap: null,
      transaction: {
        journal_map2_id: null,
        amount: 0
      },
      isInputJournal: false,
      totalJournal: 0,
      file: '',
      param: {
        reff_no: null,
        acc_account_id: null,
        date_from: null,
        date_to: null,
        keyword: "",
        offset: 0,
        limit: 20,
        order_by: "desc",
        order_at: "created_at",
      },
      data: {
        id: null,
        reff_no: '',
        total_debit: 0,
        total_credit: 0,
        datetime: null,
        description: '',
        notes: '',
        details: []
      },
      detailData: {
        acc_account_id: 0,
        acc_account_name: '',
        debit: 0,
        credit: 0,
        account: [],
        editmode: false
      },
      keyword: '',
      currentPage: 1,
      totalPage: 1,
      showModal: false,
      state: '',
      autocomplete: '',
      autocomplete2: '',
      master_accounts: []
    }
  },
  watch: {
    '$route.query': {
      handler: 'onPageQueryChange',
      deep: true
    }
  },
  methods: {
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    beautifyDate: function (date:Date) {
      return Utils.beautifyDate(date)
    },
    onPageChanged(page:any) {
      if (page) {
        this.currentPage = page
      }
      page = Math.round(this.currentPage)
      if (this.totalPage >= page) {
        this.$router.push({ query: { page } })
      }
    },
    onPageQueryChange() {
      this.param.offset = (this.currentPage - 1) * parseInt(this.param.limit)
      this.loadData()
    },
    formatDate: function(date:any) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
      if (month.length < 2)
        month = '0' + month
      if (day.length < 2)
        day = '0' + day
      return [year, month, day].join('-')
    },
    addTransaction: async function(transaction:any) {
      if (this.selectedType != 'other') {
        transaction.journal_map2_id = this.selectedMap.id
        this.data.description = this.selectedMap.name

        if (!transaction) {
          Swal.fire("Tentukan dulu tipe transaksi", "", "error")
          return
        }

        if (!transaction.amount) {
          Swal.fire("Wajib input nominal transaksi", "", "error")
          return
        }

        transaction.amount = parseInt(transaction.amount)

        // get journal map 2 detail
        var journal_map2:any = null
        await Promise.all([
          journalFactory.getMap2Detail(transaction.journal_map2_id)
        ]).then(results => {
          if (results[0].data.journal_map2) {
            journal_map2 = results[0].data.journal_map2
          }
        }).catch((e) => {
          Swal.fire("Gagal load data", "", "error")
          console.log(e)
        })

        journal_map2.details.forEach((detail:any) => {
          var journal = {
            acc_account_id: detail.acc_account_id,
            acc_account_name: detail.acc_account_name,
            debit: detail.debit == 1 ? transaction.amount : 0,
            credit: detail.credit == 1 ? transaction.amount : 0,
            account: {parent_id: detail.parent_id }
          }
          this.data.details.push(journal)
        })

        this.data.details.forEach((detail:any) => {
          detail.editmode = false
        })
      }

      this.calcDebitCredit()

      this.isInputJournal = true
      this.transaction = { journal_map2_id: null, amount: 0 }
      this.selectedTypes = null
      this.selectedType = null
    },
    loadData: function(type:any) {
      if (type === 'refresh') {
        this.onPageChanged(1)
      }

      var param = {
        reff_no: this.param.reff_no,
        acc_account_id: this.param.acc_account_id,
        date_from: "",
        date_to: "",
        offset: this.param.offset,
        limit: this.param.limit,
        order_by: "desc",
        order_at: "created_at"
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }

      this.totalPaid = 0
      Promise.all([
        journalFactory.get(param)
      ]).then(results => {
        this.journals = results[0].data.journals
        this.totalJournal = results[0].data.total_rows

        var limit = parseInt(this.param.limit)
        var totalPage = Math.round((this.totalJournal + limit - 1) / limit)
        if ((totalPage * limit - this.totalJournal) >= limit) {
          totalPage = Math.round(this.totalJournal / limit)
        }
        this.totalPage = totalPage

      }).catch((e) => {
        Swal.fire("Gagal load data", "", "error")
        console.log(e)
      })
    },
    async getSuggestions(keyword: string, type: string) {
      if (keyword.length >= 3) {
        Promise.resolve(accountFactory.search(keyword))
        .then(result => {
          this.accounts = result.data.accounts
          this.autocomplete = type
        }).catch((e) => {
          Swal.fire("Gagal melakukan request", "", "error")
          console.log(e)
          this.accounts = []
        })
      } else {
        this.accounts = []
      }
    },
    async getReffSuggestions(keyword: string, type: string) {
      if (keyword.length >= 3) {
        Promise.resolve(journalFactory.search(keyword))
        .then(result => {
          this.alljournals = result.data.journals
          this.autocomplete2 = type
        }).catch((e) => {
          Swal.fire("Gagal melakukan request", "", "error")
          console.log(e)
          this.alljournals = []
        })
      } else {
        this.alljournals = []
      }
    },
    add: function() {
      this.isInputJournal = false
      this.journalModal.show()
      this.data = {
        id: null,
        reff_no: '',
        total_debit: 0,
        total_credit: 0,
        datetime: null,
        description: '',
        notes: '',
        details: []
      }
      this.state = 'add'
    },
    addDetail: function() {

      if (!this.detailData.acc_account_id) {
        Swal.fire("No akun tidak boleh kosong", "", "error")
        return
      }

      if (!this.detailData.debit && !this.detailData.credit) {
        Swal.fire("Debit atau kredit tidak boleh kosong sekaligus", "", "error")
        return
      }

      this.data.details.push(this.detailData)
      this.calcDebitCredit()
      this.detailData = {
        acc_account_id: null,
        acc_account_name: '',
        debit: 0,
        credit: 0,
        account: [],
        editmode: false
      }
      this.keyword = ''
    },
    deleteJournal: function(index: Number, journal: any) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Pencatatan jurnal akan dihapus, anda yakin?",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          cancel: true,
          confirm: true
        },
        dangerMode: true,
      })
      .then((confirm) => {
        if (confirm.isConfirmed) {

          Promise.all([
            journalFactory.delete(journal.id),
          ]).then(results => {
            if (results[0].error == false) {
              Swal.fire("Sukses menghapus jurnal", "", "success")

              this.journals.splice(index, 1)
            } else {
              Swal.fire("Gagal menghapus jurnal", "", "error")
            }
          }).catch((e) => {
            Swal.fire("Gagal menghapus jurnal", "", "error")
            console.log(e)
          })
        }
      })
    },
    checkAll: function() {
      var el = document.querySelector('.check-all')
      var els = document.querySelectorAll('.check-one')
      var topbar = document.querySelector('.topbar')
      //check if the checkbox is checked
      if (el.checked) {
        els.forEach((el:any) => {
          el.checked = true
        })
        topbar.classList.remove('d-none')
      } else {
        els.forEach((el:any) => {
          el.checked = false
        })
        topbar.classList.add('d-none')
      }
    },
    checkOne: function() {
      var els = document.querySelectorAll('.check-one')
      var topbar = document.querySelector('.topbar')
      var show_topbar = false
      els.forEach((el:any) => {
        if (el.checked) {
          show_topbar = true
        }
      })
      if (show_topbar) {
        topbar.classList.remove('d-none')
      } else {
        topbar.classList.add('d-none')
      }
    },
    deleteSelectedJournal: function() {
      var els = document.querySelectorAll('.check-one')
      var ids = []
      var indexs = []
      els.forEach((el:any) => {
        if (el.checked) {
          //get index of checked item
          var index = el.getAttribute('data-index')
          ids.push(this.journals[index].id)
          indexs.push(index)
        }
      })    
      Swal.fire({
        title: "Apakah anda yakin?",
        text: indexs.length + " catatan jurnal akan dihapus, anda yakin?",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          cancel: true,
          confirm: true
        },
        dangerMode: true,
      })
      .then((confirm) => {
        if (confirm.isConfirmed) {
          Promise.all([
            journalFactory.bulkDelete(ids),
          ]).then(results => {
            if (results[0].error == false) {
              Swal.fire("Sukses menghapus jurnal", "", "success")
              var count = 0;
              indexs.forEach((index:any) => {
                this.journals.splice(index-count, 1)
                count++
              })
            } else {
              Swal.fire("Gagal menghapus jurnal", "", "error")
            }
          }).catch((e) => {
            Swal.fire("Gagal menghapus jurnal", "", "error")
            console.log(e)
          })
          this.checkAll();
        }
      })
    },
    async getMasterAccount(keyword: string) {
      this.master_accounts = []
      Promise.resolve(accountFactory.getByParent(keyword))
      .then(result => {
        this.master_accounts = result.data.accounts
      }).catch((e) => {
        Swal.fire("Gagal melakukan request", "", "error")
        console.log(e)
        this.master_accounts = []
      })
    },
    editDetail: function(index:number, detail:any) {
      this.getMasterAccount(detail.account.parent_id)
      this.data.details.forEach((value:any) => {
        if (value.id == detail.id && value.acc_account_id == detail.acc_account_id) {
          detail.editmode = true
        } else {
          value.editmode = false
        }
      })
    },
    saveDetail: function(index:number, detail:any) {
      this.master_accounts.forEach((value:any) => {
        if (value.id == detail.acc_account_id) {
          detail.acc_account_name = value.name
          detail.account = value
        }
      })
      detail.editmode = false
    },
    removeDetail: function(index:number, detail:any) {
      this.data.details.splice(index, 1)
      this.calcDebitCredit()
    },
    calcDebitCredit: function() {
      var total_credit =0
      var total_debit = 0
      this.data.details.forEach((detail:any) => {
        total_credit += parseInt(detail.credit)
        total_debit += parseInt(detail.debit)
      })
      this.data.total_credit = total_credit
      this.data.total_debit = total_debit
    },
    edit: function(journal_id: number) {
      this.isInputJournal = true
      Promise.resolve(journalFactory.detail(journal_id))
      .then(result => {
        if (!result.data.journal) {
          Swal.fire("Jurnal tidak ditemukan", "", "error")
          return
        }

        this.data = result.data.journal
        if (this.data.datetime) {
          this.data.datetime = new Date(this.data.datetime)
        }
        this.data.details.forEach((detail:any) => {
          detail.editmode = false
        })
        this.journalModal.show()
      }).catch((e) => {
        Swal.fire("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    save: function() {
      this.loadingSave = true

      // Validation
      if (!this.data.reff_no) {
        Swal.fire("Nomor referensi tidak boleh kosong", "", "error")
        return
      }
      if (this.data.details.length == 0) {
        Swal.fire("Rincian jurnal belum ditambahkan", "", "error")
        return
      }

      if (this.data.total_debit - this.data.total_credit != 0) {
        Swal.fire("Jurnal belum impas", "", "error")
        return
      }

      if (this.data.datetime != null) {
        this.data.datetime = Utils.datetoJSONLocal(this.data.datetime)
      }

      if (this.data.id == null || this.data.id == 0) {
        Promise.resolve(journalFactory.create(this.data))
        .then(result => {
          if (result.error == false) {
            Swal.fire("Sukses menyimpan jurnal umum", "", "success")
            this.isInputJournal = false
            this.journalModal.hide()
            this.loadData()
          } else if (result.error == true) {
            var errorMessage = result.response.data.messages
            var msg = ''
            for (let i in errorMessage) {
              msg += errorMessage[i] + "<br>"
            }
            var length = 100
            msg = msg.substring(0, length)
            Swal.fire({
              text: "Gagal Menyimpan jurnal",
              icon: "error",
              content: {
                element: "p",
                attributes: {
                  innerHTML: msg
                }
              }
            })
          }
        }).catch((e) => {
          Swal.fire("Gagal menyimpan journal", "", "error")
          console.log(e)
        }).finally(() => {
          this.loadingSave = false
        })
      } else {
        Promise.resolve(journalFactory.update(this.data.id, this.data))
        .then(result => {
          if (result.error == false) {
            Swal.fire("Sukses menyimpan jurnal umum", "", "success")
            this.isInputJournal = false
            this.journalModal.hide()
            this.loadData()
          } else if (result.error == true) {
            var errorMessage = result.response.data.messages
            var msg = ''
            for (let i in errorMessage) {
              msg += errorMessage[i] + "<br>"
            }
            var length = 100
            msg = msg.substring(0, length)
            Swal.fire({
              text: "Gagal Menyimpan jurnal",
              icon: "error",
              content: {
                element: "p",
                attributes: {
                  innerHTML: msg
                }
              }
            })
          }
        }).catch((e) => {
          Swal.fire("Gagal menyimpan tagihan", "", "error")
          console.log(e)
        }).finally(() => {
          this.loadingSave = false
        })
      }
    },
    setAccountID(search:any){
      this.param.acc_account_id = search.id
      this.accounts = []
    },
    setReffNo(search:any){
      this.param.reff_no = search.reff_no
      this.alljournals = []
    },
    setSearch(search:any){
      this.keyword = search.id
      this.detailData.acc_account_id = search.id
      this.detailData.acc_account_name = search.name
      this.detailData.account = {parent_id: search.parent_id}
      this.accounts = []
    },
    exportJournals: function() {
      var param = {
        reff_no: this.param.reff_no,
        acc_account_id: this.param.acc_account_id,
        date_from: null,
        date_to: null,
        order_by: "desc",
        order_at: "created_at",
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(journalFactory.export(param))
      .then(() => {

      }).catch((e) => {
        Swal.fire("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    importJournals: function () {
      this.imported = false

      if (this.file == '' && this.$refs.file.value != null) {
        Swal.fire("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }

      let formData = new FormData()
      formData.append('excel', this.file)
      Promise.resolve(journalFactory.importJournals(formData))
        .then((response) => {
          if (!response.data) {
            Swal.fire("Gagal melakukan request", "", "error")
            return
          }

          var total_saved = 0
          var label = 'success'
          if (response.data.saved_journals)
            total_saved = response.data.saved_journals.length

          var message = "Jurnal tersimpan: " + total_saved

          if (response.data.rejected_journals) {
            message += "<br>Tidak Tersimpan:"
            label = 'warning'
            response.data.rejected_journals.forEach(rejected => {
              message += "<br> " + rejected.reason
            })
          }

          // Swal.fire("Jurnal Tersimpan", message, label)
          Swal.fire({ html: message, icon: label, title: 'Jurnal Tersimpan!' })

          this.file = ''
          this.$refs.file.value = null
          this.loadData()

        }).catch((e) => {
          Swal.fire("Gagal melakukan request", "", "error")
          console.log(e)
        })
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    }
  },
  computed: {
    config() {
      return CurrencyInput.props.options
    }
  },
  async mounted () {
    await Promise.all([
      journalFactory.getMaps2type(),
      journalFactory.getMaps2()
    ]).then(results => {
      this.journalMap2Types = results[0].data.journal_map2_types
      var tmpJournalMaps2 = results[1].data.journal_maps2
      var journalMaps2: {[k: string]: any} = {}
      tmpJournalMaps2.forEach((map:any) => {
        var type = map.acc_journal_map2_type_id
        if (type in journalMaps2 === false) {
          journalMaps2[type] = []
        }
        journalMaps2[type].push(map)
      })

      this.journalMaps2 = journalMaps2
    })

    var journalModal = document.getElementById('journalModal')
    if (journalModal != null) {
      this.journalModal = new bootstrap.Modal(journalModal)
    }
    this.journalModal.hide()

    this.loadData()
  },
})

export default class Journal extends Vue {}
